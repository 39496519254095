import React, {useEffect} from 'react';
import { PrismicRichText } from '@prismicio/react'
import { graphql } from 'gatsby';

const GASetup = ({data}) => {
    return (
        <div>
            <h1>GA Setup</h1>
        </div>
    )
}

export default GASetup